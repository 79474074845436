@import "./fonts.css";
@import "./Swiper/swiper.css";
@import "./Swiper/swiper-pagination.css";
@import "./Swiper/swiper-navigation.css";

* {
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}
body {
  font-family: "FuturaPT";
}
/* * > :is(p, h1, h2, h3, h4, h5, h6, li) {
  font-family: inherit;
} */
* > ::-webkit-input-placeholder {
  color: gray;
  opacity: 0.75;
}
a:-webkit-any-link:focus-visible {
  outline: none;
}
:root {
  --swiper-navigation-color: white;
  --swiper-theme-color: white;
  --theme-primary-color: rgb(1, 58, 75);
  --theme-secondary-color: rgb(51, 51, 101);
}
/* Chexbox */
.checkbox-rounded:checked + .checkbox-rounded-toggle {
  background-color: #2196f3;
}

.checkbox-rounded:focus + .checkbox-rounded-toggle {
  box-shadow: 0 0 1px #2196f3;
}

.checkbox-rounded:checked + .checkbox-rounded-toggle:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

/* loading  spinner*/
.loading-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  transform: translate(-35px, -35px);
  margin-left: 3px;
}
.loading-spinner div {
  transform-origin: 40px 40px;
  animation: loading-spinner 1.2s linear infinite;
}
.loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 10%;
}
.loading-spinner.white div:after {
  background: #fff;
}
.loading-spinner.primary div:after {
  background: #085469;
}
.loading-spinner.blue div:after {
  background: blue;
}
.loading-spinner.red div:after {
  background: red;
}
.loading-spinner div:nth-child(1) {
  transform: rotate(0deg) scale(0.3);
  animation-delay: -1.1s;
}
.loading-spinner div:nth-child(2) {
  transform: rotate(30deg) scale(0.3);
  animation-delay: -1s;
}
.loading-spinner div:nth-child(3) {
  transform: rotate(60deg) scale(0.3);
  animation-delay: -0.9s;
}
.loading-spinner div:nth-child(4) {
  transform: rotate(90deg) scale(0.3);
  animation-delay: -0.8s;
}
.loading-spinner div:nth-child(5) {
  transform: rotate(120deg) scale(0.3);
  animation-delay: -0.7s;
}
.loading-spinner div:nth-child(6) {
  transform: rotate(150deg) scale(0.3);
  animation-delay: -0.6s;
}
.loading-spinner div:nth-child(7) {
  transform: rotate(180deg) scale(0.3);
  animation-delay: -0.5s;
}
.loading-spinner div:nth-child(8) {
  transform: rotate(210deg) scale(0.3);
  animation-delay: -0.4s;
}
.loading-spinner div:nth-child(9) {
  transform: rotate(240deg) scale(0.3);
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(10) {
  transform: rotate(270deg) scale(0.3);
  animation-delay: -0.2s;
}
.loading-spinner div:nth-child(11) {
  transform: rotate(300deg) scale(0.3);
  animation-delay: -0.1s;
}
.loading-spinner div:nth-child(12) {
  transform: rotate(330deg) scale(0.3);
  animation-delay: 0s;
}
@keyframes loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Full page loader */

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*              REACT SELECT CUSTOM                */

.search-filters .rs__menu-portal {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}
.search-filters .rs__menu {
  position: relative;
  display: block;
  margin-top: 0;
}
.search-filters .rs__menu-list {
  min-height: 10rem;
  max-height: 10rem;
}
.rs-container .rs__control {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: rgba(1, 58, 75, 1);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rs-container .rs__indicator-separator {
  background-color: rgba(1, 58, 75, 1);
  display: none;
}
.rs-container .rs__indicator > svg {
  color: rgba(1, 58, 75, 1);
}

.search-filters .rs__menu-list::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.search-filters .rs__menu-list::-webkit-scrollbar-thumb {
  background: rgba(1, 58, 75, 1);
  border-radius: 4px;
}

.search-filters .rs__menu-list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}
.alta .rs-alta__input input {
  box-shadow: none;
}
.alta .rs-alta__control {
  border-color: rgba(1, 58, 75, 1);
  box-shadow: 3px 3px 5px rgb(2 0 0 / 50%);
  border-radius: 0.5rem;
}
.alta .rs-alta__indicator {
  color: rgba(1, 58, 75, 1);
}
.alta .rs-alta__indicator-separator {
  background-color: rgba(1, 58, 75, 1);
}

/* Mercado pago */
