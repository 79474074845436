:root {
  --swiper-navigation-size: 12px;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  margin-top: -22px;
  z-index: 10;
  display: flex;
  cursor: pointer;
  color: var(--theme-secondary-color);
  font-weight: bold;
  border-radius: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  background-size: auto !important;
  background-position: center;
  background-color: #fff;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-right: 8px;
  padding-top: 8px;
  height: 40px;
  width: 40px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.25s, opacity 0.25s linear, transform 0.15s linear;
  outline: none;
  top: 51%;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  pointer-events: none;
}
.swiper-button-next {
  background-image: url("./next.svg");
}
.swiper-button-prev {
  background-image: url("./back.svg");
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.1);
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-lock {
  display: none;
}
