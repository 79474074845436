.link_card {
  display: flex;
  flex-direction: column;
  width: 240px;
  min-width: 240px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /* margin: 1.5rem; */
  transition: all 0, 25s;
  box-shadow: 0 12px 16px rgba(8, 8, 0, 0.253);
  transition: transform 0.6s;
}
.link_card img {
  /* background-size: 100%; */
  object-fit: cover;
  height: 290px;
  width: 240px;
}
.link_card .body {
  background-color: white;
  margin-top: -3rem;
  text-align: center;
  padding: 1rem;
}
.link_card .title {
  text-align: center;
  padding: 0.5rem;
  color: #085469;
  font-family: "FuturaPTMedium";
  font-size: 1.125rem;
}
.link_card .divider {
  display: block;
  width: 75%;
  height: 1.5px;
  background-color: #085469;
  margin: auto;
}
