/* Champagne & Limousines ********************************************* */
@font-face {
  font-display: swap;
  font-family: "Champagne & Limousines";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/Champagne&Limousines.ttf") format("truetype");
  /* url("http://localhost:3000/fonts/Champagne&Limousines.ttf") format("truetype"); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Champagne & Limousines";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/Champagne&LimousinesItalic.ttf") format("truetype");
  /* url("http://localhost:3000/fonts/Champagne&LimousinesItalic.ttf") format("truetype"); */
  font-weight: normal;
  font-style: Italic;
}
@font-face {
  font-display: swap;
  font-family: "Champagne & Limousines";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/Champagne&LimousinesBold.ttf") format("truetype");
  /* url("http://localhost:3000/fonts/Champagne&LimousinesBold.ttf") format("truetype"); */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "Champagne & Limousines";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/Champagne&LimousinesBoldItalic.ttf") format("truetype");
  /* url("http://localhost:3000/fonts/Champagne&LimousinesBoldItalic.ttf") format("truetype"); */
  font-weight: bold;
  font-style: italic;
}
/* FuturaPT ********************************************* */
@font-face {
  font-display: swap;
  font-family: "FuturaPT";
  src: local("sans-serif"), url("https://odigos.mx/fonts/FuturaPTBook.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTBook.otf") format("truetype"); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "FuturaPT";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/FuturaPTBookOblique.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTBookOblique.otf") format("truetype"); */
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: "FuturaPT";
  src: local("sans-serif"), url("https://odigos.mx/fonts/FuturaPTBold.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTBold.otf") format("truetype"); */
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "FuturaPT";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/FuturaPTBoldOblique.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTBoldOblique.otf") format("truetype"); */
  font-weight: bold;
  font-style: italic;
}

/* FuturaPTLight ********************************************* */
@font-face {
  font-display: swap;
  font-family: "FuturaPTLight";
  src: local("sans-serif"), url("https://odigos.mx/fonts/FuturaPTLight.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTLight.otf") format("truetype"); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "FuturaPTLight";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/FuturaPTLightOblique.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTLightOblique.otf") format("truetype"); */
  font-weight: normal;
  font-style: italic;
}

/* FuturaPTMedium ********************************************* */
@font-face {
  font-display: swap;
  font-family: "FuturaPTMedium";
  src: local("sans-serif"), url("https://odigos.mx/fonts/FuturaPTMedium.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTMedium.otf") format("truetype"); */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "FuturaPTMedium";
  src: local("sans-serif"),
    url("https://odigos.mx/fonts/FuturaPTMediumOblique.otf") format("truetype");
  /* url("http://localhost:3000/fonts/FuturaPTMediumOblique.otf") format("truetype"); */
  font-weight: normal;
  font-style: italic;
}
