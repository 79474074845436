.inputContainer {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
}
.inputContainerInline {
  margin: 1rem 0rem;
  display: flex;
  justify-content: center;
}

:is(.inputContainer, .inputContainerInline) label {
  font-family: "FuturaPTMedium";
  margin: 0.25rem 0rem;
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  color: #085469;
  width: auto;
}
.input {
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
  font-family: "FuturaPTMedium";
  border-radius: 8px;
  outline: none;
  color: #085469;
  border: 1px solid #085469;
  padding: 0 0.5rem;
}
