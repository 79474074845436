@tailwind base;
@import url("./Custom.css");
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .text-shadow-white {
      text-shadow: 0px 0px 20px white;
    }
    .text-shadow-black {
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
    }
    .btn-noarrows {
    }
    input[type="number"].btn-noarrows::-webkit-inner-spin-button,
    input[type="number"].btn-noarrows::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .overflow-y-clip {
      overflow-y: clip;
    }
    .overflow-x-clip {
      overflow-x: clip;
    }
    .custom-next-img {
      object-fit: cover;
      /* width: 100vw !important; */
    }
    .custom-home-bg {
      background-image: url("https://odigos.mx/images/bg.jpg");
    }
  }
}
.tw-card-inmueble {
  @apply flex flex-col w-[95vw]  sm:w-80 rounded-[10px] shadow-lg hover:shadow-2xl overflow-hidden my-6 sm:m-6 transition-all duration-300 transform hover:-translate-y-3 cursor-pointer;
}
